import Vue from 'vue'

export class MyCrossWindowCaller {
  constructor (options) {
    this.listeners = new Map()
    this.callbackHandlers = new Map()
    this.options = options
    this.targetWindow = options.targetWindow
    window.addEventListener('message', this.onReceiveMessage, false)
  }

  setTargetWindow = (window) => {
    this.options.targetWindow = window;
    this.targetWindow = window;
  }

  onReceiveMessage = async (e) => {
    if (!e.data?.type || e.data.type.indexOf('cwc-message') !== 0) {
      return
    }
    if (this.options.debug) {
      console.log('--------------- receive ' + this.options.name)
      console.log(e.data)
    }
    if (!e.data.name) {
      const fn = this.callbackHandlers.get(e.data.id)
      fn(e.data.payload)
      this.callbackHandlers.delete(e.data.id)
    } else if (this.listeners.has(e.data.name)) {
      const payload = await this.listeners.get(e.data.name)(e.data.payload)
      const message = {
        type: 'cwc-message-response',
        id: e.data.id,
        payload
      }
      if (this.options.debug) {
        console.log('--------------- callback ' + this.options.name)
        console.log(message)
      }

      this.targetWindow.postMessage(message, '*')
    }
  }

  call (name, data) {
    return new Promise((resolve) => {
      const id = Math.random().toString()
      this.callbackHandlers.set(id, (result) => {
        resolve(result)
      })
      const message = {
        type: 'cwc-message',
        id,
        name,
        payload: data
      }
      if (this.options.debug) {
        console.log('--------------- send ' + this.options.name)
        console.log(message)
      }
      this.targetWindow.postMessage(message, '*')
    })
  }

  setFunction (name, fn) {
    this.listeners.set(name, fn)
  }
}

export default {
  install (_app, options) {
    Vue.prototype.$cwc = new MyCrossWindowCaller(options)
  }
}
