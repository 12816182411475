
export default {
  name: 'TopMenu',
  computed: {
    sectionName () {
      if (this.$route.path === '/') {
        return '/'
      }

      return this.$route.path.split('/')[1]
    }
  }
}
