
export default {
  name: 'LeftSubMenu',
  props: {
    params: {
      parent_path: null,
      links: null,
      top: null // margin-top
    }
  },
  methods: {
    isActive(parent_path, child) {
      let pp = parent_path.toLowerCase()
      let r = this.$route.path.toLowerCase()
      let h = child.href.toLowerCase()

      return r === parent_path + '/' + h || (child.children && child.children.length > 0 && r.includes(pp + '/' + h))
    },
    scrollTo(id) {
      let el = document.querySelector('#' + id)
      el.scrollIntoView()
    }
  }
}

