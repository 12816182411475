export default {
  async loadModifiersAndBulks(context) {
    if (context.state.modifiers.length > 0) {
      return
    }

    const [ mods, bulks, prices, sequencePrices, sequenceOptions ] = await Promise.all(
      [
        this.$myHelper.axiosWithMyEx('/primer/getMods'),
        this.$myHelper.axiosWithMyEx('/product/getBulks'),
        this.$myHelper.axiosWithMyEx('/product/getPrimerPrices'),
        this.$myHelper.axiosWithMyEx('/v2/dictionaries/FindSeqPrices'),
        this.$myHelper.axiosWithMyEx('/v2/dictionaries/FindSeqOptions')
      ])

    context.commit('setModifiers', mods)
    context.commit('setBulks', bulks)
    context.commit('setPrimerPrices', prices)
    context.commit('setSequencePrices', sequencePrices)
    context.commit('setSequenceOptions', sequenceOptions)
  },

  async loadProducts(context, catArray) {
    if (context.state.productPrice.length > 0){
      return
    }

    const products = await this.$myHelper.axiosWithMyEx('/product/GetProducts', catArray)
    context.commit('setProducts', products)
  },

  async loadSettings(context) {
    const setting = await this.$myHelper.axiosWithMyEx('/basket/settings')
    context.commit('setSettings', setting)
  },

  async loadProductsForDiscountPage(context) {
    return await this.$myHelper.axiosWithMyEx('/product/getProductsForDiscountPage')
  }
}
