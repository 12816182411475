export default (context, inject) => {
  let myHelper = {
    revyAxiosWithMyEx(...args) {
      context.store.commit('setLoadingState', true)
      // context.store.commit('common/setLoadingState', true)

      return context.$axios.post(...args).then((response) => {
        context.store.commit('setLoadingState', false)
        // context.store.commit('common/setLoadingState', false)

          return response
        }

      ).catch(() => {
        context.store.commit('setLoadingState', false)
        // context.store.commit('common/setLoadingState', false)
      })
    },
    axiosWithMyEx(...args) {
      args[0] = '/site' + args[0]

      return context.$axios.post(...args).then((response) => {
        return (response.data.error || response.data.isError)
          ? Promise.reject({url: args[0], message: response.data.message, validateErrors: response.data.validateErrors})
          : (typeof response.data.data === 'undefined') ? response.data : response.data.data
        }
      )
    },
    priceFormat(src) {
      if (typeof src === 'undefined' || src === (!src)) {
        return 0
      }
      if (! Number.isInteger(src)) {
        src = (src * 1).toFixed(2)
      }
      return (typeof src === 'undefined' || src === (!src))
        ? 0
        : src.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g, '\$1&nbsp;')
    },
    calcDiscountPrice(sum, discountPercent) {
      return sum - Math.floor(sum / 100 * discountPercent)
    },
    isPositiveInt(src) {
      return (parseFloat(src) == parseInt(src)) &&
        (!isNaN(src)) &&
        (parseInt(src) > 0)
    },
    loadingStateDecorator() {
      return (a, b, descriptor) => {
        const original = descriptor.value

        descriptor.value = async function(...args) {
          context.$store.commit('user/setLoadingState', true)
          try {
            return await original.apply(this, args)
          } finally {
            context.$store.commit('user/setLoadingState', false)
          }
        }
      }
    },
    syntaxHighlight(json) {
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')

      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match) {
        var cls = 'number'
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key'
          } else {
            cls = 'string'
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean'
        } else if (/null/.test(match)) {
          cls = 'null'
        }

        return '<span class="' + cls + '">' + match + '</span>'
      })
    },
    onModalOpened(){
      context.store.commit('setModalState', true)
      // context.store.commit('common/setModalState', true)
    },
    onModalClosed(){
      context.store.commit('setModalState', false)
      // context.store.commit('common/setModalState', false)
    },
    isEmpty (v) {
      if (v === '' || v === null || v === undefined || (typeof v === 'object' && Object.keys(v).length < 1)) {
        return true
      }

      return false
    },
  }

  inject ('myHelper', myHelper)
}
