export default {
  setModifiers(state, value) {
    state.modifiers = value;
  },
  setBulks(state, value) {
    state.bulks = value;
  },
  setProducts(state, value) {
    state.productPrice = value;
  },
  setPrimerPrices(state, value) {
    state.primerPrices = value;
  },
  setSettings(state, value) {
    state.settings = value;
  },
  setSequencePrices(state, value) {
    state.sequencePrices = value;
  },
  setSequenceOptions(state, value) {
    state.sequenceOptions = value;
  },


}
