
import Header from '@/layouts/main/Header'
import Footer from '@/layouts/main/Footer'
import Primer from '@/assets/primer'

export default {
  name: 'MainPageLayout',
  scrollToTop: true,
  components: {
    Footer,
    Header
  },

  async mounted () {
    await Promise.all([
                        this.$store.dispatch('dictionaries/loadSettings'),
                        this.$store.dispatch('dictionaries/loadProducts', []),
                        this.$store.dispatch('dictionaries/loadModifiersAndBulks'),
                        this.$store.dispatch('user/loadUser')
                      ]);
    Primer.initStaticFields(this.$store.state.dictionaries)
    await this.$store.dispatch('basket/loadBasket')
    this.pageIsReady = true
  },
  data () {
    return {
      pageIsReady: false
    }
  }
}
