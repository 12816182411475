
import {required, email} from 'vuelidate/lib/validators'

export default {
  name: "PasswordRecoveryForm",
  props: {
    mode: null // inline | modal
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  validations: {
    login: {
      required,
      email
    }
  },
  data() {
    return {
      login: '',
      err_common: '',
      success: false,
      errorState: false,
      validEmail: true
    }
  },
  methods: {
    async send() {
      await this.emailExists()

      if (this.validEmail === true) {
        this.errorState = false
        this.success = false
        this.err_common = ''

        try {
          await this.$store.dispatch('user/restorePassword', JSON.stringify(this.login))
        } catch (ex) {
          this.errorState = true
          this.err_common = ex.message
          return
        }

        this.success = true
      } else {
        this.errorState = true
        this.err_common = 'Пользователь с таким адресом электронной почты (' + this.login + ') не зарегистрирован.'
      }
    },
    async emailExists(login) {
      try {
        this.validEmail = await this.$store.dispatch('user/userExist', JSON.stringify(login))
      } catch (ex) {
        this.errorState = true
        this.err_common = ex.message
      }
    },
    clearForm () {
      this.login = ''
      this.err_common = ''
      this.errorState = false
      this.success = false
      this.validEmail = true
    },
    closeForm () {
      if (this.mode === 'inline') {
        this.$router.push('/')
        this.clearForm()
      } else {
        this.clearForm()
        this.$emit('close')
      }
    },
    switchTo(action){
      if (this.mode === 'inline') {
        if (action === 'login') {
          this.$router.push('/auth/login')
        } else if (action === 'registration') {
          this.$router.push('/auth/registration')
        }
      } else {
        this.$emit('switch-to-action', action)
      }
    },
    resetErrorState(){
      this.err_common = ''
      this.errorState = false
    }
  }
}
