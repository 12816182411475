export class Address {
    constructor() {
        this.id = '-1';
        this.forDelete = false;
        this.clear();
    }

    static get onlyNumberRx() {
        return new RegExp(/[0-9]+/g);
    }

    toString() {
        return doDataToAddressFields.reduce(
            (add, dscr) => {
                if ((! dscr.noShow) && this[dscr.name] && this[dscr.name].trim().length > 0) {
                    let val = this[dscr.name].trim();
                    val = ((dscr.prefix) && Address.onlyNumberRx.test(val)) ? `${dscr.prefix} ${val}` :  val;
                    add.push(val);
                }
                return add;
            }, []).join(', ');
    }

    clear() {
        doDataToAddressFields.forEach(dscr => this[dscr.name] = '');
    }

    checkAddressField(fieldDscr) {
        if (fieldDscr.isRequired) {
          if (! (this[fieldDscr.name]) || this[fieldDscr.name].trim().length === 0) {
              return 'Поле должно быть заполнено.';
          }
        }
        return '';
    }

    get canSave() {
        return ! doDataToAddressFields
            .filter(item => item.isRequired)
            .some(dscr => this.checkAddressField(dscr));
    }

    get isSaved() {
        return this.id > 0;
    }
}
// https://dadata.ru/api/suggest/#about-address
export const doDataToAddressFields = [
    { name: 'postal_code', title : 'Индекс', src: 'postal_code' },
    { name: 'country', title : 'Страна', src: 'country', noShow : true },
    { name: 'region', title : 'Регион', src: 'region_with_type', noShow : true },
    { name: 'area', title : 'Район', src: 'area_with_type' },
    { name: 'city', title : 'Город', isRequired: true, src: item => item.city && item.city.trim().length > 0 ? item.city : item.settlement },
    { name: 'city_district', title : 'Район города', src:  item => item.settlement_with_type && item.settlement_with_type.trim().length > 0 ? item.settlement_with_type.trim() : item.city_district_with_type },
    { name: 'street', title : 'Улица', isRequired: true,  src: 'street_with_type' },
    { name: 'house', title : 'Дом', isRequired: true,  src: 'house', prefix:'д.' },
    { name: 'block', title : 'Корпус / строение ', src: 'block', prefix:'кор.' },
    { name: 'flat', title : 'Квартира / офис', src: 'flat', prefix:'комната' },
    { name: 'fias_code', title : 'код фиас', src: 'fias_code', noShow : true },
    { name: 'fias_id', title : 'id фиас', src: 'fias_id', noShow : true },
    { name: 'kladr_id', title : 'id кладр', src: 'kladr_id', noShow : true }
];

