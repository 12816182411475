
import 'vue-popperjs/dist/vue-popper.css'
import BasketMenu from './BasketMenu'
import AuthModal from '../modals/AuthModal'

export default {
  name: 'Profile',
  components: {
    BasketMenu,
    AuthModal
  },
  computed: {
    user () {
      return this.$store.state.user.user
    },
    clients () {
      if (!this.user || !this.user.clients) {
        return []
      }

      return this.user.clients
    },
    productsDiscount () {
      if (this.user.discountInfo && this.user.discountInfo.pdDiscount) {
        return this.user.discountInfo.pdDiscount
      }

      if (this.clients === []) {
        return 0
      }

      return this.clients.reduce((res, curr) => {
        if (curr.pdDiscount*1 > res) {
          res = curr.pdDiscount*1
        }

        return res
      }, 0)
    },
    sstDiscount () {
      if (this.user.discountInfo && this.user.discountInfo.simplePrimerPercent) {
        return this.user.discountInfo.simplePrimerPercent
      }

      if (this.clients === []) {
        return 0
      }

      return this.clients.reduce((res, curr) => {
        if (curr.simplePrimerDiscount*1 > res) {
          res = curr.simplePrimerDiscount*1
        }

        return res
      }, 0)
    },
    userHasDiscount(){
      return this.productsDiscount > 0 || this.sstDiscount > 0
    }
  },
  methods: {
    showAuthModal (action) {
      this.$modal.show(
        AuthModal,
        {
          action: action,
          mode: 'modal'
        },
        {
          adaptive: true,
          resizable: false,
          draggable: false,
          clickToClose: true,
          width: '522px',
          minWidth: 522
        },
        {
          'before-open': this.$myHelper.onModalOpened,
          'before-close': this.$myHelper.onModalClosed
        }
      )
    },
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$store.commit('user/setUser', {})
        this.$router.push('/')
      })
    },
    goToBasket(){
      this.$router.push('/basket')
    }
  }
}
