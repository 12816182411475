
import Header from '@/layouts/main/Header'
import Footer from '@/layouts/main/Footer'
import LeftMenu from '@/components/menu/LeftMenu'
import Primer from '@/assets/primer'
import Breadcrumbs from './Breadcrumbs'
import LeftOrderAndDelivery from '@/components/menu/LeftOrderAndDelivery'
import LeftTechnicalInfo from '@/components/menu/LeftTechnicalInfo'
import LeftInstructions from '@/components/menu/LeftInstructions'
import LeftMaterialsToDownload from '@/components/menu/LeftMaterialsToDownload'

export default {
  name: 'MainLayout',
  scrollToTop: true,
  head() {
    return {
      bodyAttrs: {
        class: this.anyModalOpened ? 'body-scroll-disabled' : ''
      }
    }
  },
  components: {
    LeftMaterialsToDownload,
    LeftInstructions,
    LeftTechnicalInfo,
    LeftOrderAndDelivery,
    Breadcrumbs,
    LeftMenu,
    Footer,
    Header
  },
  computed: {
    anyModalOpened() {
      return this.$store.state.anyModalOpened
      // return this.$store.state.common.anyModalOpened
    },
    showLeftMenu(){
      return this.$route.path !== '/' && this.$route.path !== '/search' && !this.$route.path.includes('auth');
    }
  },

  async mounted () {
    await Promise.all([
                  this.$store.dispatch('dictionaries/loadSettings'),
                  this.$store.dispatch('dictionaries/loadProducts', []),
                  this.$store.dispatch('dictionaries/loadModifiersAndBulks'),
                  this.$store.dispatch('user/loadUser')
                ]);

    Primer.initStaticFields(this.$store.state.dictionaries)
    await this.$store.dispatch('basket/loadBasket');
    this.pageIsReady = true
  },
  data () {
    return {
      pageIsReady: false
    }
  }
}
