export default {
  setUser(state, value) {
    state.user = value
    state.originalUser = Object.assign({}, value)
  },
  cancelUserEdit(state) {
    state.user = Object.assign({}, state.originalUser)
  },
  syncOriginalUser(state) {
    state.originalUser = Object.assign({}, state.user)
  },
  setLoginState(state, loginState) {
    state.user.loginState = loginState
  },
  setLogin(state, login) {
    state.user.login = login
  }
}
