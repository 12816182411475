
import {required} from 'vuelidate/lib/validators'

export default {
  name: 'TopSearch',
  validations() {
    return {
      search: {
        required
      }
    }
  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    doSearch () {
      this.$router.push('/search?s=' + this.search.trim())
    }
  }
}
