import { render, staticRenderFns } from "./LeftMaterialsToDownload.vue?vue&type=template&id=2665ea64&scoped=true"
import script from "./LeftMaterialsToDownload.vue?vue&type=script&lang=js"
export * from "./LeftMaterialsToDownload.vue?vue&type=script&lang=js"
import style0 from "./LeftMaterialsToDownload.vue?vue&type=style&index=0&id=2665ea64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2665ea64",
  null
  
)

export default component.exports