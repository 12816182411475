

export default {
  name: 'LeftInstructions',
  components: {},
  computed: {
    sectionName () {
      if (this.$route.path === '/') {
        return '/'
      }

      return this.$route.path.substring(1).split('/').reduce((res, curr) => {
        if (Object.keys(this.menuOptionsNormilized).includes(curr.toLowerCase())) {
          res = curr.toLowerCase()
        }

        return res
      }, '')
    },
    currentSection () {
      if (!this.menuOptionsNormilized[this.sectionName]) {
        return null
      }

      return this.menuOptionsNormilized[this.sectionName]
    },
    menuOptionsNormilized () {
      let res = {}

      Object.keys(this.menuOptions).forEach(k => {
        if (this.$route.path.toLowerCase().includes(this.menuOptions[k].href.toLowerCase())) {
          res[k.toLowerCase()] = this.menuOptions[k]
        }
      })

      return res
    }
  },
  data () {
    return {
      path: '/',
      menuOptions: {
        'competent-cells': {
          href: '/products/cloning/competent-cells',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/CC004.pdf', text: 'XL1-Blue электрические', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/CC001.pdf', text: 'XL1-Blue<br>химические', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/CC002.pdf', text: 'BL21(DE3)pLysS химические', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/CC003.pdf', text: 'JM110 (dam-) химические', absolute: true},
          ]
        },
        'ladders': {
          href: '/products/evaluation/ladders',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/DNA_Ladder_NL001.pdf', text: '1 kb DNA Ladder (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/DNA_Ladder_NL002.pdf', text: '<nobr>100+ bp DNA Ladder (pdf)</nobr>', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/DNA_Ladder_NL003.pdf', text: '<nobr>50+ bp DNA Ladder (pdf)</nobr>', absolute: true}
          ]
        },
        'plasmid-kits': {
          href: '/products/isolation/plasmid-kits',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/BC121S.pdf', text: '<nobr>Plasmid Miniprep Color (pdf)</nobr>', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/BC221S.pdf', text: '<nobr>Plasmid Miniprep 2.0 (pdf)</nobr>', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/BC224.pdf', text: '<nobr>Plasmid Midiprep 3.0 (pdf)</nobr>', absolute: true}
          ]
        },
        'extract-dna-ffpe': {
          href: '/products/isolation/extract-dna-ffpe',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/FFPE.pdf', text: 'ExtractDNA FFPE (pdf)', absolute: true}
          ]
        },
        'extract-dna-blood-and-cells': {
          href: '/products/isolation/extract-dna-blood-and-cells',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/ExtractDNABC.pdf', text: 'ExtractDNA Blood & Cells (pdf)', absolute: true}
          ]
        },
        'cleanup': {
          href: '/products/isolation/cleanup',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/BC022.pdf', text: 'Cleanup Standard (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/BC041.pdf', text: 'Cleanup S-Cap (pdf)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/BC023.pdf', text: 'Cleanup Mini (pdf)', absolute: true}
          ]
        },
        'cleanmag': {
          href: '/products/isolation/cleanmag',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/BM135.pdf', text: 'CleanMag DNA PCR (pdf)', absolute: true}
          ]
        },
        'magstand': {
          href: '/products/isolation/cleanmag/magstand',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/BC036M_BC037_MagStand.pdf', text: 'Магнитные штативы МагСтенд (pdf)', absolute: true}
          ]
        },
        'intact-rna': {
          href: '/products/isolation/intact-rna',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/intactRNA.pdf', text: 'IntactRNA (pdf)', absolute: true}
          ]
        },
        'rna-solo': {
          href: '/products/isolation/rna-solo',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/RNASolo.pdf', text: 'RNA Solo (pdf)', absolute: true}
          ]
        },
        'bisquick': {
          href: '/products/isolation/bisquick',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/EG002.pdf', text: 'BisQuick (pdf)', absolute: true}
          ]
        },
        'extract-rna': {
          href: '/products/isolation/extract-rna',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/extractRNA.pdf', text: 'ExtractRNA (pdf)', absolute: true}
          ]
        },
        'clean-rna': {
          href: '/products/isolation/clean-rna',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/BC033_CleanRNA.pdf', text: 'CleanRNA&nbsp;Standard&nbsp;(pdf)', absolute: true}
          ]
        },
        'encyclo': {
          href: '/products/pcr/polymerases/encyclo',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Encyclo_PLUS.pdf', text: 'Набор Encyclo Plus PCR kit (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/Encyclo_polymerase.pdf', text: 'Encyclo полимераза (pdf)', absolute: true}
          ]
        },
        'tersus': {
          href: '/products/pcr/polymerases/tersus',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Tersus_PLUS_PK221.pdf', text: 'Набор Tersus Plus PCR kit (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/Tersus_pol_PK123.pdf', text: 'Tersus полимераза (pdf)', absolute: true}
          ]
        },
        'snp-detect': {
          href: '/products/pcr/polymerases/snp-detect',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/SNPdetect.pdf', text: 'SNPdetect полимераза (pdf)', absolute: true}
          ]
        },
        'ktn-hs': {
          href: '/products/pcr/polymerases/ktn-hs',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/KTN-HS.pdf', text: 'KTN-HS полимераза (pdf)', absolute: true}
          ]
        },
        'taq': {
          href: '/products/pcr/polymerases/taq',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Taq-pol.pdf', text: 'Taq ДНК-полимераза (pdf)', absolute: true}
          ]
        },
        'hs-taq': {
          href: '/products/pcr/polymerases/hs-taq',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/HSTaq-pol.pdf', text: 'HS Taq ДНК-полимераза (pdf)', absolute: true}
          ]
        },
        'buffers': {
          href: '/products/pcr/polymerases',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/buffer_PB001.pdf', text: 'Taq Turbo буфер (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/buffer_PB002.pdf', text: 'Taq Turbo буфер без Mg<sup>2+</sup> (pdf)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/buffer_PB003.pdf', text: 'Taq Red буфер (pdf)', absolute: true},
            {nn: 4, type: 'file', href: '/kit-user-manuals/buffer_PB004.pdf', text: 'Taq Red буфер без Mg<sup>2+</sup> (pdf)', absolute: true},
          ]
        },
        'sybr': {
          href: '/products/pcr/polymerases/sybr',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/PB025-SYBR.pdf', text: 'Интеркалирующий краситель SYBR Green I (pdf)', absolute: true}
          ]
        },
        'mycoplasma': {
          href: '/products/mycoplasma',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/MycoReport.pdf', text: 'MycoReport (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/Myco_Real-Time.pdf', text: 'Myco Real-Time (pdf)', absolute: true}
          ]
        },
        'lenti': {
          href: '/products/lenti',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Lenti_LP001.pdf', text: 'LVT-TagRFP (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/Lenti_LP002.pdf', text: 'LVT-TurboFP635 (pdf)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/Lenti_LP004.pdf', text: 'LVT-TagGFP2 (pdf)', absolute: true},
            {nn: 4, type: 'file', href: '/kit-user-manuals/Lenti_LP005.pdf', text: 'LVT-FusionRed (pdf)', absolute: true},
            {nn: 5, type: 'file', href: '/kit-user-manuals/Lenti_LP006.pdf', text: 'LVT-FusionRed-H2B (pdf)', absolute: true},
            {nn: 6, type: 'file', href: '/kit-user-manuals/Lenti_LP007.pdf', text: 'LVT-FusionRed-Mem (pdf)', absolute: true},
          ]
        },
        'mmlv': {
          href: '/products/cdna/synthesis',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/MMLV_SK021.pdf', text: 'Набор MMLV RT kit (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/MMLV_SK022.pdf', text: 'Обратная транскриптаза MMLV (pdf)', absolute: true}
          ]
        },
        'mint': {
          href: '/products/cdna/synthesis',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Mint-OT.pdf', text: 'Обратная транскриптаза Mint (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/Mint_ru.pdf', text: 'Набор Mint (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/Mint-2.pdf', text: 'Набор Mint-2 (pdf)', absolute: true},
          ]
        },
        'magnus': {
          href: '/products/cdna/synthesis/magnus',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Magnus.pdf', text: 'Обратная транскриптаза Magnus (pdf)', absolute: true},
          ]
        },
        'onetube': {
          href: '/products/cdna/synthesis',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/OneTubeTaqMan.pdf', text: 'OneTube RT-PCR TaqMan (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/OneTubeSYBR.pdf', text: 'OneTube RT-PCR SYBR (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/OneTubeRTPCRmix.pdf', text: 'OneTube RT-PCRmix (pdf)', absolute: true},
          ]
        },
        'trimmer2': {
          href: '/products/cdna/normalization',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Trimmer-2.pdf', text: 'Набор Trimmer-2 (pdf, англ.)', absolute: true},
          ]
        },
        'race': {
          href: '/products/cdna/race',
          children: [
            {nn: 1, type: 'file', href: '/support/technologies/step-out-race', text: 'Технология Step-out RACE', absolute: true},
          ]
        },
        'mint-race-cdna': {
          href: '/products/cdna/race',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/SKS03.pdf', text: 'Инструкция (pdf)', absolute: true},
            {nn: 1, type: 'file', href: '/support/technologies/step-out-race', text: 'Технология Step-out RACE', absolute: true},
          ]
        },
        'mint-race-primers': {
          href: '/products/cdna/race',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Mint-Race.pdf', text: 'Инструкция (pdf)', absolute: true},
            {nn: 1, type: 'file', href: '/support/technologies/step-out-race', text: 'Технология Step-out RACE', absolute: true},
          ]
        },
      }
    }
  },
  methods: {

  }
}
