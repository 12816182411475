import Primer from '@/assets/primer.js'

export default function PrimerCollection(primers, store) {
  this.store = store
  this.items = primers
    ? primers.map((item) => {
      const primer = Primer.parse(item.sequenceView)
      primer.options = item.options
      primer.scale = item.scale * 1 !== 0 ? item.scale * 1 : ''
      primer.numberInOrder = item.numberInOrder
      primer.id = item.id
      primer.name = item.name
      primer.editState = item.editState
      return primer
    })
    : []

  this.editPrimer = {
    id: NaN,
    cursor() {
    },
    logicalCursor: 0
  }

  this.saveInProgress = false
}

PrimerCollection.prototype.getIdForNewPrimer = function () {
  return this.items.reduce((minId, item) => (item.id <= minId ? (item.id - 1) : minId), -1)
}

PrimerCollection.prototype.addPrimers = function (newPrimers, store) {
  if (!Array.isArray(newPrimers)) {
    newPrimers = [newPrimers]
  }
  let newPrimerId = this.getIdForNewPrimer()
  newPrimers.forEach((item) => {
    if (isNaN(item.id)) {
      item.id = newPrimerId
      newPrimerId--
    }
    this.items.push(item)
  })

  store.dispatch('basket/loadPrimersPrice', {primers: newPrimers})
  this.editPrimer.id = this.showPrimers().length > 0 ? this.showPrimers()[this.showPrimers().length - 1].id : NaN
}

PrimerCollection.buildPrimersDto = function (srcPrimers, number = 0) {
  return srcPrimers.map((item) => {
    number += item.forDelete ? 0 : 1;
    return {
      id: item.id,
      name: item.name,
      forDelete: item.forDelete,
      scale: item.scale,
      sequence: item.sequence,
      mods: item.mods,
      options: item.options,
      numberInOrder: item.id * 1 >= 0 ? item.numberInOrder : number,
      editState: item.editState
    }
  });
}

PrimerCollection.prototype.deletePrimers = function (primers, forceRemove = false) {
  const idForDeleteArray = primers.map(item => item.id)

  this.items.forEach((i, key) => {
    if (idForDeleteArray.indexOf(i.id) !== -1) {
      if (i.id < 0) {
        this.items.splice(key, 1)
      } else {
        i.forDelete = true
      }
    }
  })

  /*idForDeleteArray.forEach((id) => {
    const fndIdx = _(this.items).findIndex(['id', id])
    (this.items[fndIdx*1].id < 0 || forceRemove)
      ? this.items.splice(fndIdx*1, 1)
      : this.items[fndIdx*1].forDelete = true
  })*/
}

PrimerCollection.prototype.showPrimers = function (editState) {
  return this.items.filter(item => (!item.forDelete) && (editState ? item.editState === editState : true))
}

PrimerCollection.prototype.isErrors = function () {
  return this.showPrimers().some(item => item.isErrors())
}

PrimerCollection.prototype.getState = function (isModified) {
  if (!this.items) {
    return ''
  }
  if (this.saveInProgress) {
    return 'saving'
  }

  const filtered = (typeof isModified === 'undefined')
    ? this.items
    : (isModified
        ? this.items.filter(item => item.mods.length > 0)
        : this.items.filter(item => item.mods.length === 0)
    )

  if (filtered.some(item => item.state === 'loading')) {
    return 'loading'
  }

  if (filtered.every(item => item.state === 'load')) {
    return 'load'
  }

  return ''
}

