export default () => ({
  modifiers: [],
  bulks: [],
  productPrice: [],
  primerPrices: null,
  sequencePrices: [],
  sequenceOptions:[],
  settings: {},
  staticSettings: {
    dadataKey: '8ad7dc63420e2f4ced30334c78022c40a7f1e5e4',
    searchForSiteUrl: 'https://evrogen.ru/site/api/search',
  }
})
