
import {mapGetters} from 'vuex'

export default {
  name: 'BasketMenu',
  computed: {
    ...mapGetters('basket', {
      counters: 'counters'
    }),
    basketCount() {
      return this.counters.count*1
    },
    basketSumStr() {
      return this.counters.sum ? this.counters.sum : 0
    },
    basketIsNotEmpty() {
      return this.basketCount && this.basketCount > 0
    }
  },
  methods: {
    goToBasket(){
      this.$router.push('/basket')
    }
  }
}
