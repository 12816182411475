import * as _ from 'lodash'

export default {
  getAffiliateNameByClientId : (state) => (clientId) => {
    const client =  _.find(state.user.clients, client => client.id * 1 === clientId * 1)

    if (client && client.affiliate) {
      return [client.affiliate.name || client.name, client.affiliate.inn, client.affiliate.kpp, client.affiliate.address, client.affiliate.legal_address]
    }

    return ''
  }
}
