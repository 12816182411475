

export default {
  name: 'LeftOrderAndDelivery',
  components: {},
  computed: {
    sectionName () {
      if (this.$route.path === '/') {
        return '/'
      }

      return this.$route.path.substring(1).split('/').reduce((res, curr) => {
        if (Object.keys(this.menuOptionsNormilized).includes(curr.toLowerCase())) {
          res = curr.toLowerCase()
        }

        return res
      }, '')
    },
    currentSection () {
      if (!this.menuOptionsNormilized[this.sectionName]) {
        return null
      }

      return this.menuOptionsNormilized[this.sectionName]
    },
    menuOptionsNormilized () {
      let res = {}

      Object.keys(this.menuOptions).forEach(k => {
        res[k.toLowerCase()] = this.menuOptions[k]
      })

      return res
    }
  },
  data () {
    return {
      path: '/',
      menuOptions: {
        'products': {
          href: '/products',
          caption: 'Заказ и доставка',
          children: [
            {nn: 1, type: 'link', href: 'contacts', text: 'Заказ и техническая поддержка', absolute: false},
            {nn: 2, type: 'link', href: '/order/shipping', text: 'Условия доставки', absolute: true},
            {nn: 3, type: 'link', href: '/order/return', text: 'Условия возврата', absolute: true},
            {nn: 4, type: 'link', href: '/order/special-offers', text: 'Специальные предложения', absolute: true}
          ]
        }
      }
    }
  },
  methods: {
    isActive(section, child) {
      let l = this.buildLink(section, child).toLowerCase()
      let r = this.$route.path.toLowerCase()

      return r === l || r === l  + '/' || (child.children && r.includes(l))
    },
    buildLink(section, child) {
      let res = section.href

      if (child.href !== '') {
        res += '/' + child.href
      }

      return res
    }
  }
}
