export default {
  strict: false,
  state: () => {
    return {
      isLoadingNow: false,
      anyModalOpened: false
    }
  },
  getters: {

  },
  actions: {

  },
  mutations: {
    setLoadingState(state, value) {
      state.isLoadingNow = value
    },
    setModalState(state, value) {
      state.anyModalOpened = value
    }
  }
}
