
import LoginForm from '../LoginForm'
import PasswordRecoveryForm from '../PasswordRecoveryForm'
import RegistrationForm from '../RegistrationForm'

export default {
  name: "AuthModal",
  props: {
    action: null, // login | registration | restore_password
    mode: null // modal | inline
  },
  components: {
    RegistrationForm,
    PasswordRecoveryForm,
    LoginForm
  },
  mounted() {
    this.currentAction = this.action
  },
  data() {
    return {
      currentAction: ''
    }
  },
  methods: {
    switchToAction(action){
      this.currentAction = action
    }
  }
}
