import { render, staticRenderFns } from "./AuthModal.vue?vue&type=template&id=8d92175a&scoped=true"
import script from "./AuthModal.vue?vue&type=script&lang=js"
export * from "./AuthModal.vue?vue&type=script&lang=js"
import style0 from "./AuthModal.vue?vue&type=style&index=0&id=8d92175a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d92175a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginForm: require('/var/www/localhost/evrogen.ru/components/LoginForm.vue').default,PasswordRecoveryForm: require('/var/www/localhost/evrogen.ru/components/PasswordRecoveryForm.vue').default,RegistrationForm: require('/var/www/localhost/evrogen.ru/components/RegistrationForm.vue').default})
