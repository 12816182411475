
import {required} from 'vuelidate/lib/validators'

export default {
  name: "LoginForm",
  props: {
    mode: null // inline | modal
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  validations: {
    login: {
      required
    },
    password: {
      required
    }
  },
  data() {
    return {
      login: '',
      password: '',
      errorState: ''
    }
  },
  methods: {
    async doLogin() {
      this.errorState = ''
      let args = {
        login: this.login,
        password: this.password
      }
      try {
        await this.$store.dispatch('user/login', args);
      } catch (ex) {
        this.errorState = ex.message;
        return;
      }
      await this.$store.dispatch('user/loadUser');
      this.closeLoginForm();
    },
    clearForm () {
      this.login = ''
      this.password = ''
      this.errorState = false
    },
    closeLoginForm () {
      this.clearForm()
      this.$emit('close')
    },
    switchTo(action){
      if (this.mode === 'inline') {
        if (action === 'restore_password') {
          this.$router.push('/auth/restore-password')
        } else if (action === 'registration') {
          this.$router.push('/auth/registration')
        }
      } else {
        this.$emit('switch-to-action', action)
      }
    }
  }
}
