
import {email, minLength, required} from 'vuelidate/lib/validators'

export default {
  name: 'RegistrationForm',
  props: {
    mode: null // inline | modal
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  validations(){
    return {
      newUser: {
        lName: {
          required
        },
        fName: {
          required
        },
        login: {
          required,
          email
        },
        phone: {
          required
        },
        password: {
          required,
          minLength: minLength(6)
        }
      },
      passwordConfirmation: {
        required,
        check (val) {
          return val === this.newUser.password
        }
      }
    }
  },
  data() {
    return {
      err_common: '',
      dataSent: false,
      success: false,
      passwordConfirmation: '',
      newUser: {
        loginState: 'new',
        passwordConfirmed: false,
        login: '',
        lName: '',
        mName: '',
        fName: '',
        phone: '',
        password: '',
        contacts: [
          {type: 'mail', contact: ''},
          {type: 'phone', contact: ''}
        ]
      },
      loginExists: false,
      formIsSent: false,
      loginBackendCheckFailed: false
    }
  },
  methods: {
    async doRegister() {
      this.formIsSent = true
      this.loginBackendCheckFailed = false

      if (!this.$v.newUser.$invalid && !this.$v.passwordConfirmation.$invalid) {
        await this.isUnique(this.newUser.login)

        if (this.loginExists === false) {
          this.dataSent = false
          this.success = false
          this.err_common = ''

          this.newUser.contacts[0].contact = this.newUser.login
          this.newUser.contacts[1].contact = this.newUser.phone

          try {
            await this.$store.dispatch('user/registration', this.newUser)
            this.success = true
          } catch (ex) {
            this.dataSent = true
            this.err_common = ex.message

            if (this.err_common.match(/получателей/)){
              this.err_common = 'Регистрация не удалась. Возможно адрес электронной почты (логин) содержит ошибку.'
              this.loginBackendCheckFailed = true
            }
          }
        }
      }
    },
    async isUnique(login) {
      try {
        this.loginExists = await this.$store.dispatch('user/userExist', JSON.stringify(login))
      } catch (ex) {
        this.dataSent = true
        this.err_common = ex.message
      }
    },
    clearForm () {
      this.err_common = ''
      this.formIsSent = false
      this.loginBackendCheckFailed = false
      this.dataSent = false
      this.success = false
      this.passwordConfirmation = ''
      this.newUser = {
        loginState: 'new',
        passwordConfirmed: false,
        login: '',
        lName: '',
        mName: '',
        fName: '',
        phone: '',
        password: '',
        contacts: [
          {type: 'mail', contact: ''},
          {type: 'phone', contact: ''}
        ]
      }
      this.loginExists = false
    },
    closeForm () {
      this.clearForm()

      if (this.mode === 'inline') {
        this.$router.push('/')
      } else {
        this.$emit('close')
      }
    },
    switchTo(action){
      this.clearForm()

      if (this.mode === 'inline') {
        if (action === 'login') {
          this.$router.push('/auth/login')
        }
      } else {
        this.$emit('switch-to-action', action)
      }
    }
  }
}
