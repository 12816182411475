

export default {
  name: 'LeftmaterialsToDownload',
  components: {},
  computed: {
    sectionName () {
      if (this.$route.path === '/') {
        return '/'
      }

      return this.$route.path.substring(1).split('/').reduce((res, curr) => {
        if (Object.keys(this.menuOptionsNormilized).includes(curr.toLowerCase())) {
          res = curr.toLowerCase()
        }

        return res
      }, '')
    },
    currentSection () {
      if (!this.menuOptionsNormilized[this.sectionName]) {
        return null
      }

      return this.menuOptionsNormilized[this.sectionName]
    },
    menuOptionsNormilized () {
      let res = {}

      Object.keys(this.menuOptions).forEach(k => {
        if (this.$route.path.toLowerCase().includes(this.menuOptions[k].href.toLowerCase())) {
          res[k.toLowerCase()] = this.menuOptions[k]
        }
      })

      return res
    }
  },
  data () {
    return {
      path: '/',
      menuOptions: {
/*        'insider': {
          href: '/products/nomotech/insider',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Insider_IVD.pdf', text: 'Инструкция к набору (pdf)', absolute: true},
          ]
        },*/
        'BRCA': {
          href: '/products/nomotech/BRCA',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/BRCA.pdf', text: 'Инструкция к набору (pdf)', absolute: true},
          ]
        },
        'insider-fullras': {
          href: '/products/nomotech/insider-fullras',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/FullRAS.pdf', text: 'Инструкция Инсайдер FullRAS (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/KRAS.pdf', text: 'Инструкция Инсайдер KRAS (pdf)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/NRAS.pdf', text: 'Инструкция Инсайдер NRAS (pdf)', absolute: true},
            {nn: 4, type: 'file', href: '/kit-user-manuals/BRAF.pdf', text: 'Инструкция Инсайдер BRAF (pdf)', absolute: true},
            {nn: 5, type: 'file', href: '/kit-user-manuals/FullRAS_PO.zip', text: 'Программное обеспечение (exe)', absolute: true},
            {nn: 6, type: 'file', href: '/kit-user-manuals/FullRAS_PO.pdf', text: 'Инструкция к ПО (pdf)', absolute: true}
          ]
        },
        'insider-egfr': {
          href: '/products/nomotech/insider-egfr',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/EGFR.pdf', text: 'Инструкция Инсайдер<br>EGFR (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/R3003_EGFR.pdf', text: 'Инструкция Инсайдер<br>EGFR ex18 (pdf)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/R3004_EGFR.pdf', text: 'Инструкция Инсайдер<br>EGFR ex19 (pdf)', absolute: true},
            {nn: 4, type: 'file', href: '/kit-user-manuals/R3005_EGFR.pdf', text: 'Инструкция Инсайдер EGFR ex20 S768I, ins (pdf)', absolute: true},
            {nn: 5, type: 'file', href: '/kit-user-manuals/R3006_EGFR.pdf', text: 'Инструкция Инсайдер EGFR ex20 T790M (pdf)', absolute: true},
            {nn: 6, type: 'file', href: '/kit-user-manuals/R3007_EGFR.pdf', text: 'Инструкция Инсайдер EGFR ex20 C797S (pdf)', absolute: true},
            {nn: 7, type: 'file', href: '/kit-user-manuals/R3008_EGFR.pdf', text: 'Инструкция Инсайдер<br>EGFR ex21 (pdf)', absolute: true},
            {nn: 8, type: 'file', href: '/kit-user-manuals/EGFR_PO.zip', text: 'Программное обеспечение (exe)', absolute: true},
            {nn: 9, type: 'file', href: '/kit-user-manuals/EGFR_PO.pdf', text: 'Инструкция к ПО (pdf)', absolute: true}
          ]
        },
        'CYP2C9-VKORC1-CYP4F2': {
          href: '/products/nomotech/CYP2C9-VKORC1-CYP4F2',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/GenTest_CYP2C9_VKORC1.pdf', text: 'Инструкция к набору (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/GenTest.zip', text: 'Программное обеспечение (exe)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/GenTest_PO.pdf', text: 'Инструкция к ПО (pdf)', absolute: true},
          ]
        },
        'CYP2C19': {
          href: '/products/nomotech/CYP2C19',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/GenTest_CYP2C19.pdf', text: 'Инструкция к набору (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/GenTest.zip', text: 'Программное обеспечение (exe)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/GenTest_PO.pdf', text: 'Инструкция к ПО (pdf)', absolute: true},
          ]
        },
        'SLCO1B1': {
          href: '/products/nomotech/SLCO1B1',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/GenTest_SLCO1B1.pdf', text: 'Инструкция к набору (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/GenTest.zip', text: 'Программное обеспечение (exe)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/GenTest_PO.pdf', text: 'Инструкция к ПО (pdf)', absolute: true},
          ]
        },
        'CES1': {
          href: '/products/nomotech/CES1',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/GenTest_CES1.pdf', text: 'Инструкция к набору (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/GenTest.zip', text: 'Программное обеспечение (exe)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/GenTest_PO.pdf', text: 'Инструкция к ПО (pdf)', absolute: true},
          ]
        },
        'F2-F5': {
          href: '/products/nomotech/F2-F5',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/GenTest_F2_F5.pdf', text: 'Инструкция к набору (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/GenTest.zip', text: 'Программное обеспечение (exe)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/GenTest_PO.pdf', text: 'Инструкция к ПО (pdf)', absolute: true},
          ]
        },
        'NAT2': {
          href: '/products/nomotech/NAT2',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/GenTest_M_NAT2.pdf', text: 'Инструкция к набору (pdf)', absolute: true},
          ]
        },
/*        'MGMT': {
          href: '/products/nomotech/MGMT',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/EpiGenTest_MGMT.pdf', text: 'Инструкция к набору (pdf)', absolute: true}
          ]
        },
        'ffpe': {
          href: '/products/nomotech/ffpe',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/ExtractDNA_FFPE_IVD.pdf', text: 'Инструкция к набору (pdf)', absolute: true},
          ]
        },*/
        'extract-dna2': {
          href: '/products/nomotech/extract-dna2',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/ExtractDNA2.pdf', text: 'Инструкция к набору (pdf)', absolute: true}
          ]
        },
      }
    }
  },
  methods: {

  }
}
