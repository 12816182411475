import * as Address from '@/assets/address'

export default {
  async loadUser(context) {
    let response = {}

    try {
      response = await this.$myHelper.axiosWithMyEx('/profile/getProfile')
    } catch (ex) {

    }

    if (response) {
      response.clients && response.clients.forEach(function(item) {
        item.isEditNow = false
        item.affiliate = item.affiliate ? item.affiliate : { id: '', name: '' }
      })

      response.addresses && response.addresses.forEach(address => Object.setPrototypeOf(address, Address.Address.prototype))
      context.commit('setUser', response)
    }
  },

  async setNewLoginAndSendCode(context, newLogin) {
    await this.$myHelper.axiosWithMyEx('/profile/setNewLogin',  JSON.stringify(newLogin))
    context.commit('setLoginState', 'isChanging')
  },

  async checkCodeAndSetNewLogin(context, confirmCode) {
    const newLogin = await this.$myHelper.axiosWithMyEx('/profile/checkLoginChangeCode', JSON.stringify(confirmCode))
    context.commit('setLoginState', 'new')
    context.commit('setLogin', newLogin)
  },

  async changePassword(context, data) {
    await this.$myHelper.axiosWithMyEx('/auth/changePassword', data)
  },

  async saveSearch(context, payload) {
    return await this.$myHelper.axiosWithMyEx('/controllers/profile/saveSearch', payload)
  },

  async addPersonClient(context) {
    const name = [context.state.user.lName, context.state.user.fName, context.state.user.mName].filter(r => !!r).join(' ')
    const client = {
      id: null,
      forDelete: false,
      isEditNow: false,
      is_person: 1,
      name: name,
      users: [context.state.user.id],
      affiliate: { name: name },
      source: 'site'
    };
    client.id = await context.dispatch('saveClient', client)
    context.state.user.clients.push(client)
    return client.id
  },

// --------------------------------------------------------------------------------------------------------------------------
  async login(context, data) {
    return await this.$myHelper.axiosWithMyEx('/auth/login', data)
  },

  async logout(context) {
    return await this.$myHelper.axiosWithMyEx('/auth/logout')
  },

  async userExist(context, login) {
    return await this.$myHelper.axiosWithMyEx('/auth/userExist', login)
  },

  async registration(context, login) {
    return await this.$myHelper.axiosWithMyEx('/auth/registration', login)
  },

  async registrationComplete(context, id) {
    return await this.$myHelper.axiosWithMyEx('/auth/registrationComplete', id)
  },

  async restorePassword(context, id) {
    return await this.$myHelper.axiosWithMyEx('/auth/restorePassword', id)
  },

  async setNewPassword(context, data) {
    return await this.$myHelper.axiosWithMyEx('/auth/setNewPassword', data)
  },

  async saveUserFields(context, user) {
    const responseUser = await this.$myHelper.axiosWithMyEx('/profile/saveProfile', user)
    responseUser.clients = context.state.user.clients
    responseUser.addresses = context.state.user.addresses
    context.commit('setUser', responseUser)
  },

  async saveClient(context, client) {
    if (!client.forDelete) {
      client.affiliate = await this.$myHelper.axiosWithMyEx('/profile/findOrCreateAffiliate', { name: client.affiliate.name, isPerson: client.is_person * 1 === 1 })
    }
    return await this.$myHelper.axiosWithMyEx('/profile/saveClient', client)
  },

  async saveAddress(context, address) {
    return await this.$myHelper.axiosWithMyEx('/profile/saveAddress', address)
  }
}
