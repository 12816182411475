export default function (to, from, savedPosition) {
  // return window.scrollTo({ top: 0, behavior: 'smooth' })

  if (to.hash) {
    return {selector: to.hash}
  } else {
    return window.scrollTo({ top: 0, behavior: 'smooth' })
    // return { x: 0, y: 0 }
  }
}
