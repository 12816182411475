///
// руками не трогать. если закомментируете, на забудьте раскомментировать
///
export default function ({ app, req, route, redirect }) {
  if (process.server) {
    if (route.path === '/' && !req.session.desktop && !route.fullPath.includes('desktop') && !app.$ua.isFromPc() && !app.$ua.isFromCrawler()) {
      redirect('https://m.evrogen.ru')
    }
  }
  // Client-side
  if (process.client) {
    if (route.path === '/' && !route.fullPath.includes('desktop') && !app.$ua.isFromPc() && !app.$ua.isFromCrawler()) {
      redirect('https://m.evrogen.ru')
    }
  }
}

