

export default {
  name: 'Footer',
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
