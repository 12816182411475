

export default {
  name: 'LeftTechnicalInfo',
  components: {},
  computed: {
    sectionName () {
      if (this.$route.path === '/') {
        return '/'
      }

      return this.$route.path.substring(1).split('/').reduce((res, curr) => {
        if (Object.keys(this.menuOptionsNormilized).includes(curr.toLowerCase())) {
          res = curr.toLowerCase()
        }

        return res
      }, '')
    },
    currentSection () {
      if (!this.menuOptionsNormilized[this.sectionName]) {
        return null
      }

      return this.menuOptionsNormilized[this.sectionName]
    },
    menuOptionsNormilized () {
      let res = {}

      Object.keys(this.menuOptions).forEach(k => {
        if (this.$route.path.toLowerCase().includes(this.menuOptions[k].href.toLowerCase())) {
          res[k.toLowerCase()] = this.menuOptions[k]
        }
      })

      return res
    }
  },
  data () {
    return {
      path: '/',
      menuOptions: {
        'quantum': {
          href: '/products/evaluation/quantum',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/QuantumDNA_.pdf', text: 'Инструкция QuantumDNA (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/quantumDNA_programming_CFX96_.pdf', text: 'Программирование амплификатора CFX96 (pdf)', absolute: true},
            {nn: 3, type: 'file', href: '/kit-user-manuals/quantumDNA_analysis_CFX96_.pdf', text: 'Анализ данных с помощью ПО CFX96 (pdf)', absolute: true},
            {nn: 4, type: 'file', href: '/downloads/Quantum_protocol.prcl', text: 'Шаблон протокола ПЦР для набора QuantumDNA (prcl)', absolute: true},
            {nn: 5, type: 'file', href: '/downloads/Quantum_plate.pltd', text: 'Шаблон плашки для набора QuantumDNA (pltd)', absolute: true}
          ]
        },
        'cdna': {
          href: '/products/cdna',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/transcriptionOneTubeSYBR.pdf', text: 'Обратная транскрипция и амплификация кДНК: в одну или в две стадии? (pdf)', absolute: true},
          ]
        },
        'mixes': {
          href: '/products/pcr/mixes',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Evrogen-PCR-recommendation.pdf', text: 'Рекомендации по постановке ПЦР (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/Evrogen-PCR-products.pdf', text: 'Сравнение свойств полимераз (pdf)', absolute: true}
          ]
        },
        'polymerases': {
          href: '/products/pcr/polymerases',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Evrogen-PCR-recommendation.pdf', text: 'Рекомендации по постановке ПЦР (pdf)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/Evrogen-PCR-products.pdf', text: 'Сравнение свойств полимераз (pdf)', absolute: true}
          ]
        },
        'vectors': {
          href: '/products/cloning/vectors',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/pAL2-T.pdf', text: '<nobr>Инструкция pAL2-T (pdf)</nobr>', absolute: true},
            {nn: 2, type: 'file', href: '/products/pAL-TA/pAL2-T_seq.txt', text: 'Сиквенс pAL2-T (txt)', absolute: true},
            {nn: 2, type: 'file', href: '/products/pAL-TA/pAL2-T.gb', text: 'Карта pAL2-T (gb)', absolute: true},
            {nn: 2, type: 'file', href: '/kit-user-manuals/pKAN-T.pdf', text: '<nobr>Инструкция pKAN-T (pdf)</nobr>', absolute: true},
            {nn: 2, type: 'file', href: '/products/pAL-TA/pKAN-T_seq.txt', text: 'Сиквенс pKAN-T (txt)', absolute: true},
            {nn: 2, type: 'file', href: '/products/pAL-TA/pKAN-T.gb', text: 'Карта pKAN-T (gb)', absolute: true},
          ]
        },
        'quick-ta': {
          href: '/products/cloning/quick-ta',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/Quick-TA.pdf', text: 'Инструкция (pdf)', absolute: true}
          ]
        },
        'practicum': {
          href: '/products/practicum',
          children: [
            {nn: 1, type: 'file', href: '/kit-user-manuals/practicum.pdf', text: 'Инструкция (pdf)', absolute: true}
          ]
        },
      }
    }
  },
  methods: {

  }
}
