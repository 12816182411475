import Vue from 'vue'
import * as Sentry from '@sentry/vue'

import {Integrations} from '@sentry/tracing'

if (process.env.NODE_ENV === 'production') {
  try {
    Sentry.init({
      Vue: Vue,
      dsn: 'https://ef39350fadc74ede9ada70c92789485c@o1043899.ingest.sentry.io/6013696',
      integrations: [new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "evrogen.ru", /^\//]
      })],
      tracesSampleRate: 1.0,
    })
  } catch(e) {}
}
