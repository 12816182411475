
export default {
  name: 'UserDiscount',
  scrollToTop: true,
  layout: 'main/Index',
  components: {

  },
  head() {
    return {
      title: 'Персональные скидки',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: ''
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    clientsWithDiscount () {
      if (!this.user || !this.user.clients) {
        return []
      }
      return this.user.clients.filter(cl => cl.simplePrimerDiscount || cl.sequenceDiscount || cl.pdDiscount );
    },
  },
  validations() {
    return {

    }
  },
  async mounted () {
    await this.$store.dispatch('user/loadUser')

    if (!this.user || !this.user.id) {
      this.$router.push('/auth/login?ret=/profile/discount')
    }

    this.discountProducts = await this.$store.dispatch('dictionaries/loadProductsForDiscountPage')
  },
  data() {
    return {
      discountProducts: []
    }
  },
  methods: {

  }
}
